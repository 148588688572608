import React, { useState, useEffect, useRef } from "react";
import TherapistSection from "../../organisms/TherapistSection";
import Header from "@components/Header";
import Footer from "@components/Footer";
import ResponseSubmit from "./ResponseSubmit";
import {
  social,
  sendEventObj,
  triggerGoogleAddConversion,
  googleAdsLabel,
} from "utils/constants";
import * as FullStory from "@fullstory/browser";
import FACEBOOK_PIXEL_1 from "@components/Pixel/facebook/pixel-1";
import BookASession from "../../organisms/BookASession";
import ClientReviewSection from "../../organisms/ClientReviewSection";
import CentersSection from "@organisms/CentersSection";
import ContactSection from "@organisms/ContactSection";
import HeroBanner from "@organisms/HeroBanner";
import TeamOfPractitioners from "@organisms/TeamOfPractitioners";
import HelpYourChildSection from "@organisms/HelpYourChildSection";
import MediaPresenceSection from "@organisms/MediaPresenceSection";
import SunshineStorySection from "@organisms/SunshineStorySection";
import SunshineUnique from "@organisms/SunshineUnique";
import FaqSection from "@organisms/FaqSection";
import Image from "next/image";
import Container from "@atoms/Container";
import HeaderDropdownCommon from "@molecules/HeaderDropdownCommon";
import Head from "next/head";
import ComingSoonBanner from "@molecules/ComingSoonBanner";

function SunShineLanding({
  handleBookAppointment,
  handleBookAssessment,
  handleMoreArticle,
  therapists,
}) {
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const onClose = (showSuccess = false) => {
    if (showSuccess) setShowSuccessDialog(showSuccess);
  };

  useEffect(() => {
    sendEventObj({ visits_sunshine_key: true }, "user_visits_sunshine");
    triggerGoogleAddConversion(googleAdsLabel.SUNSINE_PAGE_VIEW_LABEL);
    FullStory.init({
      orgId: process.env.NEXT_PUBLIC_FULLY_STORY_TRACKING_ID,
    });

    return () => {
      if (FullStory) FullStory.shutdown();
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '24527289993537119');
          fbq('track', 'PageView');
        `;
    document.head.appendChild(script);

    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=24527289993537119&ev=PageView&noscript=1" />`;
    document.body.appendChild(noscript);

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  const handleClick = (url, from) => {
    sendEventObj(
      { clicked_on_book_session_key: `${from}` },
      "user_clicked_on_book_session"
    );
    window.open(url, "_blank");
  };

  const visitCenter = useRef(null);
  const exploreSessions = useRef(null);
  const contactUsSection = useRef(null);

  const onClickFooter = (value) => {
    if (value === "visitCenter") {
      visitCenter.current.scrollIntoView({ behavior: "smooth" });
    }
    if (value === "exploreSessions") {
      exploreSessions.current.scrollIntoView({ behavior: "smooth" });
    }
    if (value === "contactUsSection") {
      contactUsSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFooterClick = (value) => {
    if (value === "contactUsSection") {
      contactUsSection.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const consultationData = [
    {
      img: "/assets/images/confused.svg",
      title: "Initial Consultation",
      description: "Understand Your Child's Specific Needs to Enhance Support.",
    },
    {
      img: "/assets/images/Multitasking.svg",
      title: "Comprehensive Evaluation",
      description:
        "Gain Holistic Insights and Receive Tailored Recommendations.",
    },
  ];

  const ProfessionalListData = [
    {
      img: "/assets/images/sunshine/professionals1.jpg",
      heading: "Child Psychologists",
      description:
        "Helping kids navigate emotions, building resilience for lifes journey.",
      viewMorelink:
        "https://www.lissun.app/sunshine/parent-child-counselling-for-kids",
    },
    {
      img: "/assets/images/sunshine/professionals2.jpg",
      heading: "Special Educators",
      description:
        "Crafting personalized learning paths, celebrating every milestone.",
      viewMorelink:
        "https://www.lissun.app/sunshine/special-education-for-kids",
    },
    {
      img: "/assets/images/sunshine/professionals3.jpg",
      heading: "Speech Therapists",
      description:
        "Making words a playground, turning communication challenges into victories.",
      viewMorelink: "https://www.lissun.app/sunshine/speech-therapy-for-kids",
    },
    {
      img: "/assets/images/sunshine/professionals4.jpg",
      heading: "Occupational Therapists",
      description:
        "Fine-tuning life skills, making each day a step towards independence.",
      viewMorelink:
        "https://www.lissun.app/sunshine/occupational-therapy-for-kids",
    },
    {
      img: "/assets/images/sunshine/professionals5.jpg",
      heading: "Behavioral Therapists",
      description:
        "Rewriting behavior scripts, fostering positive transformations.",
      viewMorelink:
        "https://www.lissun.app/sunshine/behavioural-therapy-for-kids",
    },
  ];

  const childAssessmentServicesData = [
    {
      heading: "IQ Assessments",
      description:
        "Unveiling cognitive potential, a key to tailored growth strategies.",
      img: "/assets/images/help41.png",
    },
    {
      heading: "ADHD Assessments",
      description:
        "Navigating attention styles, fostering focus and resilience.",
      img: "/assets/images/help42.png",
    },
    {
      heading: "Autism Screening",
      description:
        "Understanding unique perspectives, embracing neurodiversity.",
      img: "/assets/images/help43.png",
    },
    {
      heading: "Psychological Assessments",
      description:
        "Tailoring insights to unveil individual strengths and needs.",
      img: "/assets/images/help44.png",
    },
    {
      heading: "SLD Assessments",
      description:
        "Unlocking learning pathways, turning challenges into triumphs.",
      img: "/assets/images/help45.png",
    },
  ];

  const UniqueSunshineData = [
    {
      mainImage: "/assets/images/Unique1.svg",
      title: " Stimulating Environment for your child",
      imageUrl1: "/assets/images/SunshineCheck.svg",
      sentence1: "Thoughtfully designed fostering curiosity and growth.",
      sentence2:
        "Engaging activities that ignite a love for learning and exploration",
      sentence3: "Encouraging creativity and a sense of wonder",
    },
    {
      mainImage: "/assets/images/Unique3.svg",
      title: " Experienced Care Team and Affordable",
      imageUrl1: "/assets/images/SunshineCheck.svg",
      sentence1: "Dedicated professionals with a wealth of expertise",
      sentence2: "Commitment to your child's well-being and development",
      sentence3: "High-quality care without breaking your budget",
    },
    {
      mainImage: "/assets/images/sunshine/Unique2.svg",
      title: " Regular Connect with Parents",
      imageUrl1: "/assets/images/SunshineCheck.svg",
      sentence1: "Scheduled updates and meetings to keep parents well-informed",
      sentence2: "Active parent involvement in child's progress",
      sentence3:
        "Building a collaborative partnership between our team and your family",
    },
  ];

  const clientReviews = [
    {
      name: "Jaspreet",
      image: "/assets/images/sunshine/jaspreet.svg",
      content:
        "Very nice soothing cooperative environment. Thanks to the Sunshine team for their work and help",
      location: "East Patel Nagar",
    },
    {
      name: "Sanya",
      image: "/assets/images/sunshine/sanya.svg",
      content:
        "Extremely satisfied with their way of understanding the concerns of parents for kids and work towards their improvement. I could see positive changes within weeks. Not only  individual therapies even group therapies are organised regularly to make sure kids grow socially also. The environment is very calm and positive and love the cooperative and understanding nature of the therapists",
      location: "East Patel Nagar",
    },
    {
      name: "Bhabhravi",
      image: "/assets/images/sunshine/bhabhravi.svg",
      content:
        "Thank you sunshine team for being helpful and considerate towards my child. ADHD and learning disability were my major concerns. They worked on different disciplines, directed us to achieve our common goals and helped both of us to improve our bond. Special thanks for their uphill battle to help my child grow emotionally healthy",
      location: "Gurgaon",
    },
    {
      name: "Priyanka",
      image: "/assets/images/sunshine/priyanka.svg",
      content:
        "What an amazing winter camp organised by sunshine by lissun... good opportunity for children to enhance their skills and I see overall development being focused as per the age group. Very well managed centre glad to be a part of the winter camp which is designed very very thoughtfully... cheers to the entire team",
      location: "Gurgaon",
    },
    {
      name: "Riya Kaushik",
      image: "/assets/images/sunshine/riya.svg",
      content:
        "The speech therapy has been proving beneficial for our daughter. The behaviour therapy has helped a lot on how to tackle her non-cooperative behaviour at home. The sitting time for different activities has increased from 5-10 minutes to about half an hour. Thanks a lot for such a great help",
      location: "Gurgaon",
    },
    {
      name: "Rahul Dixit",
      image: "/assets/images/sunshine/rahul.svg",
      content:
        "Sunshine is one of the best places in the city for kids with autism spectrum disorders. The staff is very polite and well trained. They also have very methodical and planned interventions. Special thanks to the entire team for a great effort",
      location: "Lucknow",
    },
  ];
  const centersList = [
    {
      location: " Delhi(East Patel Nagar) ",
      image: "/assets/images/sunshine/Cover_Sunshine East Patel Nagar.png",
      address:
        "9/14, East Patel Nagar, Opposite Hotel Amrapali Grand, New Delhi, Delhi 110008",
      direction: "https://maps.app.goo.gl/ptJXo6ub4pGVuogx8?g_st=iw",
      phone: "+91 9821688144",
      link: "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi",
    },
    {
      location: " Delhi(Indirapuram) ",
      image: "/assets/images/sunshine/Cover_Sunshine Indirapuram.jpg",
      address:
        "1st floor Block B, Plot No 1, Opp. Cambridge School, Shakti Khand 4, Indirapuram, Ghaziabad, Uttar Pradesh 201014",
      direction: "https://maps.app.goo.gl/F1SyCk5QJ81W8y9V7",
      phone: "+91 9625117823",
      link: "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi",
    },
    {
      location: " Delhi(Pitampura) ",
      image: "/assets/images/sunshine/Cover_Sunshine Pitampura.jpg",
      address: "E1070, Ground floor, Saraswati Vihar, Pitampura, New Delhi 110034",
      direction: "https://maps.app.goo.gl/DNnTgK5mhGt1B1bdA",
      phone: "+91 9667565838",
      link: "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi",
    },
    {
      location: "Pune(Magarpatta) ",
      image: "/assets/images/sunshine/Cover_Sunshine Pune Hadapsar.jpg",
      address:
        "Gandharv Galaxia, S. No. 135, Raskar Chowk, Magarpatta area, Revenue village Hadapsar, Tal. Haveli, dist. Pune",
      direction: "https://shorturl.at/uevY1",
      phone: "+91 9717858269",
      link: "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-pune",
    },
    {
      location: "Lucknow(Gomti Nagar) ",
      image: "/assets/images/sunshine/Cover_Sunshine Lucknow.jpg",
      address:
        "5/1, Lower Ground Floor, behind Pizza Wings, Vishal Khand - 4, Vishal Khand, Gomti Nagar, Lucknow, Uttar Pradesh 226010",
      direction: "https://maps.app.goo.gl/Ro63j263xFDs5kxu8",
      phone: "+91 9717857643",
      link: "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-lucknow",
    },
    {
      location: "Gurgaon(Sector 51)",
      image: "/assets/images/sunshine/Cover_Sunshine Gurgaon.jpg",
      address:
        "1st Floor, Plot No, J-81,51, opposite Presidium School, Gurgaon, Mayfield Garden, Sector 51, Gurugram, Samaspur, Haryana 122001",
      direction: "https://maps.app.goo.gl/F56vF9yR4xjSzy2r8",
      phone: "+91 9717857568",
      link: "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-gurgaon",
    },
    
  ];

  const locationList = [
    {
      textColor: "text-secondary-910",
      location: "Delhi ",
      address: "(East Patel Nagar)",
      phone: "+91 9821688144",
      email: "sunshinepatelnagar@lissun.app",
      viewMorelink:
        "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-delhi",
    },
    {
      textColor: "text-secondary-700",
      location: "Delhi ",
      address: "(Indirapuram)",
      phone: "+91 9625117823",
      email: "info.sunshine@lissun.app",
      viewMorelink:
        "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-Indirapuram",
    },
    {
      textColor: "text-secondary-920",
      location: "Delhi ",
      address: "(Pitampura)",
      phone: "+91 9667565838",
      email: "info.sunshine@lissun.app",
      viewMorelink:
        "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-Pitampura",
    },
    {
      textColor: "text-primary-700",
      location: "Gurgaon ",
      address: "(Sector 51)",
      phone: "+919717857568",
      email: "info.sunshine@lissun.app",
      viewMorelink:
        "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-gurgaon",
    },
    {
      textColor: "text-secondary-920",
      location: "Lucknow ",
      address: "(Gomti Nagar)",
      phone: "+91 9717857643",
      email: "info.sunshine@lissun.app",
      viewMorelink:
        "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-lucknow",
    },
    {
      textColor: "text-secondary-700",
      location: "Pune",
      address: "(Magarpatta)",
      phone: "+91 9717858269",
      email: "info.sunshine@lissun.app",
      viewMorelink: "https://www.lissun.app/sunshine/child-counseling-mental-health-service-in-pune",
    }
    
    
  ];

  const faqData = {
    heading: "FAQs",
    accordionList: [
      {
        title: "What is Sunshine by LISSUN, and how can it help my child?  ",
        content:
          "Sunshine by LISSUN specialises in addressing the developmental and mental health needs of children through a team of dedicated specialists, including special educators, occupational therapists, and psychologists. It's designed to support children facing challenges in areas like learning, behaviour, and speech development. ",
      },
      {
        title: "What issues does Sunshine help with?  ",
        content:
          "Sunshine aids children with diverse developmental and mental health hurdles, encompassing learning difficulties, speech delays, behavioural issues, and social skills development, including those associated with neurodevelopmental disorders like ADHD and autism. Our specialists offer personalised support for each child's distinct needs, striving for significant progress in these vital areas.  ",
      },
      {
        title: "What is Sunshine's process for working with a new client? ",
        content:
          "At Sunshine, our approach begins with a comprehensive understanding of each child's unique background. During the initial visit, a clinical psychologist collects the child's history. This is followed by a detailed psychological assessment. Within a week, we share the assessment report with the client's family and discuss our findings. Based on this discussion, we design a personalised therapy plan tailored to meet the child's individual needs. Our therapists then perform specialised assessments to develop targeted strategies and set clear goals. The number of weekly therapy sessions is determined by these goals. Therapy officially starts with a focus on achieving set objectives, and after each session, we provide therapy notes to track progress and outcomes. ",
      },
      {
        title:
          "Who is part of the team at Sunshine, and what are their qualifications? ",
        content:
          "Our multidisciplinary team at Sunshine comprises special educators, occupational therapists, speech therapists, ABA therapists, child psychologists, and developmental paediatricians. Each member holds degrees and certifications in relevant fields such as psychology, paediatric therapy, social work, and special education. Additionally, they engage in continuous professional development to remain abreast of the latest practices, offering comprehensive support customised to meet each child's unique needs.   ",
      },
      {
        title: "What age group does Sunshine cater to? ",
        content:
          "Sunshine provides services for children and adolescents ranging from infancy to 18 years old. Whether just a few months old or nearing adulthood, we offer tailored support and resources designed to meet the developmental needs of each age group throughout their growth. ",
      },
      {
        title: "What are Sunshine's operating days and hours? ",
        content:
          "Sunshine is open from Monday to Saturday, each week. Our hours of operation are from 10:00 AM to 7:00 PM, providing services and support throughout the day. ",
      },
      {
        title:
          "How are parents involved in their child's therapy at Sunshine, and how can they track their child's progress ",
        content:
          "Parental involvement is key at Sunshine. We engage parents in the therapy process, update them regularly with detailed progress reports, and guide them on supporting therapy at home. This collaborative approach enables parents to actively participate in and monitor their child's development.",
      },
      {
        title:
          "What progress and timeline can I expect for my child's development at Sunshine? ",
        content:
          "The progress and timeline for your child's development vary, as each child's needs and challenges are unique. At Sunshine, our aim is for noticeable improvement in targeted areas, with regular assessments and updates for parents. ",
      },
      {
        title: "Can Sunshine provide services remotely or only in person? ",
        content:
          "Sunshine offers both remote and in-person services, allowing us to support children and families regardless of their location. We have seen tremendous success with our online therapies as well. ",
      },
      {
        title:
          "What types of therapy does Sunshine offer, and how do they differ? ",
        content:
          "Sunshine provides a variety of therapies, including speech therapy, occupational therapy, ABA therapy, special education, and guidance from child psychologists and developmental paediatricians. Each therapy targets distinct developmental aspects: speech therapy fosters communication, occupational therapy bolsters daily skills and independence, ABA therapy refines behavioural patterns, special education addresses learning challenges, while child psychologists and developmental paediatricians focus on emotional and psychological growth. ",
      },
      {
        title:
          "What is the difference between Occupational Therapy and Physiotherapy?  ",
        content:
          "Occupational therapy (OT) focuses on enhancing children's cognitive, sensory, and fine motor skills to improve their ability to perform daily activities and engage in learning. It's rooted in developmental and mental health support. Physiotherapy (PT), conversely, concentrates on physical development, helping children improve their strength, coordination, and mobility, often after injury. OT addresses developmental tasks and self-care, while PT focuses on physical movement and rehabilitation.    ",
      },
      {
        title:
          "What if I'm not sure which type of therapy is right for my child?",
        content:
          "If you're unsure about your child's needs or the right therapy, come to us with any concerns you or your child are facing. We're here to help evaluate specific areas or offer a comprehensive evaluation for a holistic understanding of your child's situation, guiding you towards the most suitable therapy options.",
      },
      {
        title:
          "Are Sunshine's services only for children with diagnosed conditions? ",
        content:
          "Not exclusively. Sunshine aims to support any child who might benefit from our specialised services, including those without formal diagnoses but facing challenges in learning, behaviour, or development. ",
      },
      {
        title:
          "Does Sunshine offer any group therapy or social skills groups for children?",
        content:
          "Yes, Sunshine offers group therapy sessions and social skills groups for children, and we also host regular camps, such as summer and winter camps. These camps are designed to enhance social skills and peer interaction in a fun, group setting, providing a supportive space for children to practise and grow together.",
      },
      {
        title: "What happens if I move to another city? ",
        content:
          "If you're unsure about your child's needs or the right therapy, come to us with any concerns you or your child are facing. We're here to help evaluate specific areas or offer a comprehensive evaluation for a holistic understanding of your child's situation, guiding you towards the most suitable therapy options.If your family moves to another city, Sunshine strives to ensure continuity in your child's developmental support. Depending on the availability of our services and programs in your new location, we may offer remote sessions or assist in transitioning to local services to maintain the progress and support for your child. ",
      },
    ],
  };

  const mediaLists = [
    {
      icon: "/assets/images/media11.png",
      title: "How To Support Your Kid’s Mental Well-Being? ",
      description:
        "Mental health is an already vast concept, with little to no formal education and dispersion of information and knowledge available to people. In such",
      url: "#",
    },
    {
      icon: "/assets/images/media22.png",
      title:
        "Behavioral Change And Mental Health Of Your Child: An Expert Guide",
      description:
        "The third law of motion states that each action has an equal and opposite reaction, in a similar fashion it applies to humans too. Each experience is",
      url: "#",
    },
    {
      icon: "/assets/images/media11.png",
      title: "How To Support Your Kid’s Mental Well-Being? ",
      description:
        "Mental health is an already vast concept, with little to no formal education and dispersion of information and knowledge available to people. In such",
      url: "#",
    },
    {
      icon: "/assets/images/media22.png",
      title:
        "Behavioral Change And Mental Health Of Your Child: An Expert Guide",
      description:
        "The third law of motion states that each action has an equal and opposite reaction, in a similar fashion it applies to humans too. Each experience is",
      url: "#",
    },
    {
      icon: "/assets/images/media11.png",
      title: "How To Support Your Kid’s Mental Well-Being? ",
      description:
        "Mental health is an already vast concept, with little to no formal education and dispersion of information and knowledge available to people. In such",
      url: "#",
    },
    {
      icon: "/assets/images/media22.png",
      title:
        "Behavioral Change And Mental Health Of Your Child: An Expert Guide",
      description:
        "The third law of motion states that each action has an equal and opposite reaction, in a similar fashion it applies to humans too. Each experience is",
      url: "#",
    },
  ];

  const services = [
    {
      name: "Speech Therapy",
      link: `${process.env.NEXT_PUBLIC_MAIN_URL}sunshine/speech-therapy-for-kids`,
    },
    {
      name: "Occupational Therapy",
      link: `${process.env.NEXT_PUBLIC_MAIN_URL}sunshine/occupational-therapy-for-kids`,
    },
    {
      name: "Special Education",
      link: `${process.env.NEXT_PUBLIC_MAIN_URL}sunshine/special-education-for-kids`,
    },
    {
      name: "Behavioural Therapy",
      link: `${process.env.NEXT_PUBLIC_MAIN_URL}sunshine/behavioural-therapy-for-kids`,
    },
    {
      name: "Parent & Child Counselling",
      link: `${process.env.NEXT_PUBLIC_MAIN_URL}sunshine/parent-child-counselling-for-kids`,
    },
  ];

  const locations = [
    {
      name: "Delhi",
      submenu: [
        {
          name: "East Patel Nagar",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-delhi`,
        },
        {
          name: "Sector26",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-delhi`,
        },
      ],
    },
    {
      name: "Gurgaon",
      submenu: [
        {
          name: "Sector26",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-gurgaon`,
        },
        {
          name: "Sector84",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-gurgaon`,
        },
      ],
    },
    {
      name: "Lucknow",
      submenu: [
        {
          name: "Gomtinagar",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-lucknow`,
        },
        {
          name: "Ashiyana",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-lucknow`,
        },
      ],
    },
    {
      name: "Pune",
      submenu: [
        {
          name: "Magarpatta",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-pune`,
        },
        {
          name: "Pimpri",
          link: `${process.env.NEXT_PUBLIC_SUNSHINE_URL}/sunshine/child-counseling-mental-health-service-in-pune`,
        },
      ],
    },
  ];

  const contactUsSunshineHeaderRef = useRef(null);

  const onClickContactUs = (value) => {
    if (value === "contactUsSunshineHeader") {
      contactUsSunshineHeaderRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Head>
        <title>
          {" "}
          {
            "Best Online Child Counselling Service Provider in India | Lissun"
          }{" "}
        </title>
        <meta
          property="og:url"
          content={"https://sunshine.lissun.app/"}
          key="url"
        />
        <meta
          property="og:title"
          content={
            "Best Online Child Counselling Service Provider in India | Lissun"
          }
          key="title"
        />
        <meta
          property="og:description"
          content={
            "Sunshine offers expert speech therapy, occupational therapy, and more for children. Improve communication, motor skills, and development with our personalized online sessions.."
          }
          key="description"
        />
        <meta
          name="description"
          content={
            "Sunshine offers expert speech therapy, occupational therapy, and more for children. Improve communication, motor skills, and development with our personalized online sessions.."
          }
          key="description"
        />
      </Head>
      <div className="  ">
        <section className="HeaderSection  z-10 bg-secondary-700  ">
          {process.env.NEXT_PUBLIC_APP_ENV === "awsprod" && (
            <FACEBOOK_PIXEL_1 />
          )}
          {showSuccessDialog && (
            <ResponseSubmit close={() => setShowSuccessDialog(false)} />
          )}
          <div className={""}>
            <div className="bg-secondary-700 pb-4">
              <Container type="type12 -px-4">
                <HeaderDropdownCommon
                  bgColor="bg-secondary-700"
                  services={services}
                  locations={locations}
                  logoUrl="/assets/images/sunshine/whitelogo.svg"
                  onClickFooter={handleFooterClick}
                  onClickContactUs={onClickContactUs}
                />
              </Container>
            </div>
          </div>
        </section>

        <div className="hidden md:block">
          <div className="bg-[#88C399] flex items-center justify-center ">
            <ComingSoonBanner
              message="Coming Soon: New Sunshine by LISSUN centres coming to xyz and xyz very soon!"
              // linkText="Know More"
              linkUrl="#"
              bgColor="bg-[#88C399]"
              textColor="text-white"
              linkColor="text-white"
              imgSrc="/assets/images/Comingsoon1.png"
              imgSrc1="/assets/images/Comingsoon1.png"
              imgAlt="ComingSoonIcon"
            />
          </div>
        </div>

        <div className="md:hidden block">
          <div className="bg-[#88C399] flex items-center justify-center ">
            <ComingSoonBanner
              message="Coming Soon: New Sunshine by LISSUN centres coming to xyz and xyz very soon!"
              // linkText="Know More"
              linkUrl="#"
              bgColor="bg-[#88C399]"
              textColor="text-white"
              linkColor="text-white"
              imgSrc="/assets/images/Comingsoon1.png"
              imgWidth={48}
              imgHeight={48}
              imgAlt="ComingSoonIcon"
            />
          </div>
        </div>

        <HeroBanner onClose={onClose} consultationData={consultationData} />

        <div ref={exploreSessions}>
          <TeamOfPractitioners ProfessionalListData={ProfessionalListData} />
        </div>

        <HelpYourChildSection
          childAssessmentServicesData={childAssessmentServicesData}
          handleBookAssessment={handleBookAssessment}
        />

        <SunshineUnique UniqueSunshineData={UniqueSunshineData} className=" " />

        <ClientReviewSection clientReviews={clientReviews} />

        <div ref={visitCenter}>
          <CentersSection
            id="nearCenter"
            centersList={centersList}
            onClose={onClose}
          />
        </div>
        <SunshineStorySection />

        <MediaPresenceSection handleMoreArticle={handleMoreArticle} />

        <TherapistSection therapists={therapists} />

        <section className="relative bg-secondary-40 pb-12 lg:pb-20">
          <div ref={contactUsSection} className="relative w-full ">
            <div ref={contactUsSunshineHeaderRef}>
              <ContactSection locationList={locationList} />
            </div>
          </div>
          <FaqSection
            className=" text-center mx-auto"
            data={faqData}
            bgColor="bg-secondary-40"
            sectionPadding=""
            themeColor="secondary-700"
            onClickOpenChatWidget={() => setIsMessengerOpen(!isMessengerOpen)}
          />
          <div className="relative z-auto -bottom-24 lg:-bottom-[150px] left-1/2 transform -translate-x-1/2">
            <BookASession handleBookAppointment={handleBookAppointment} />
            <div className="absolute -top-[150px] mt-10 md:hidden block -z-20">
              <Image
                src="/assets/images/sunshine/FAQlion.svg"
                width={500}
                height={160}
                alt="lion Illustration"
              />
            </div>
            <div className="absolute -top-[150px] left-[32%] mt-10 hidden md:block -z-20">
              <Image
                src="/assets/images/sunshine/vectorlion.svg"
                width={400}
                height={110}
                alt="lion Illustration"
              />
            </div>
          </div>
        </section>
      </div>

      <Footer
        onClickFooter={onClickFooter}
        social={social}
        containerType="type11"
      />
    </>
  );
}

export default SunShineLanding;