import Text from "@atoms/Text";
import Image from "next/image";
import React from "react";

const LocationCard = ({ textColor, location, address, phone, email, viewMorelink }) => {
  return (
    <>
      <div className={`p-4 md:p-5 rounded-2xl w-11/12 md:w-[270px] h-[150px] md:h-[212px] bg-secondary-930 shadow-b-xl ml-5`}>
        <div className="flex items-start justify-start gap-2 md:gap-1 flex-col overflow-scroll md:overflow-hidden">
          <div>
          <header className="header w-full hidden md:block">
              <h3 className={`${textColor} text-base md:text-2xl font-semibold md:font-bold leading-6 md:leading-8 text-left`}>
                {location}
              </h3>
              {address && (
                <Text className={`${textColor} text-base md:text-2xl font-semibold md:font-bold leading-6 md:leading-8 text-left mt-1 block`}>
                  {address}
                </Text>
              )}
            </header> 
            <header className="header w-full md:hidden block">
              <h3 className={`${textColor} text-xl font-semibold md:font-bold leading-6 md:leading-8 text-left`}>
                {location} {address}
              </h3>
              
            </header>
          </div>
          <div className="contact-info">
            <Text className="text-base text-left flex items-center gap-2 whitespace-nowrap mt-0 md:mt-3">
              <div className="mt-1.5">
                <Image
                  src="/assets/images/sunshine/Call.jpg"
                  width={16}
                  height={16}
                />
              </div>
              {phone}
            </Text>
            <Text className="text-base text-left flex items-center gap-2 whitespace-nowrap">
              <div className="flex justify-center">
                <Image
                  src="/assets/images/sunshine/mail121.svg"
                  width={16}
                  height={16}
                />
              </div>
              {email}
            </Text>
          </div>
        </div>
        <div className="text-center flex justify-center items-center w-full mx-auto mt-2 lg:mt-4">
          <a href={viewMorelink} target="_blank" rel="noopener noreferrer" className="text-[#EF833A] underline mr-2 text-sm ">
            View More
          </a>
          <Image
            src="/assets/images/Vector.svg"
            width={15}
            height={15}
            className="mt-1.5"
          />
        </div>
      </div>
    </>
  );
};

export default LocationCard;
